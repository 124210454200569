/**
 * Payment Gallery Images
 * This is a widget that is responsible for click handling of
 * payment block gallery images for landing pages.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/9014552f49f8553de3d8605a11baecf7242053d9/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/9014552f49f8553de3d8605a11baecf7242053d9/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/9014552f49f8553de3d8605a11baecf7242053d9/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, domAttr, on) {
  return declare([], {
    mainImageElement: null,
    galleryImagePaths: [],
    constructor: function (params) {
      this.mainImageElement = document.querySelector(".mcnPaymentMainImage img.mcnImage");
      var elements = document.querySelectorAll(".mcnPaymentGalleryImages div.mcnImage");
      if (elements.length > 0) {
        this._setupImages(elements, params);
      }
    },
    _setupImages: function (elements, params) {
      elements.forEach(function (element, idx) {
        var backgroundImage = domAttr.get(element, "data-img-src");
        if (backgroundImage) {
          this.galleryImagePaths.push(backgroundImage);
          this._setupOnClickHandler(element, idx);
        }
      }.bind(this));
    },
    _setupOnClickHandler: function (element, idx) {
      // listen for click on the element
      on(element, "click", function (e) {
        e.preventDefault();
        e.stopPropagation();
        this._switchImage(this.galleryImagePaths[idx] || "");
      }.bind(this));
    },
    _switchImage: function (path) {
      domAttr.set(this.mainImageElement, "src", path);
    }
  });
});